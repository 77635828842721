import React from 'react';
import { Link } from "gatsby"

import './header.scss';

function Header({ toggleMenuState, isMenuDisplayed, lang = 'default', location = null, showLanguageSelector = true }) {
    
    const homeLink = `/${lang === 'de' ? 'de' : ''}`;    

    return (
        <header>
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="container Navigation__container">
                    <div className="navbar-brand">
                        <a className="navbar-item" href={homeLink}>
                          
                            <img src="/img/logo-highres.png" class="logo__white" alt="Voa Labs Logo" height="35" />
                            <img src="/img/logo-highres-black.png" class="logo__black" alt="Voa Labs Logo" height="35" />
                        </a>
                    </div>
                    <div className="navbar-menu">
                        <div className="navbar-end">
                            {showLanguageSelector ? (
                                <div className={"navbar-item Language__wrapper " + (isMenuDisplayed ? '' : 'visible')} href="/">
                                    <Link className={"Language__item" + (lang === 'default' || lang === 'en'  ? ' selected' : '')} to={location.pathname.replace('/de/', '/')}>EN</Link>
                                    <Link className={"Language__item" + (lang === 'de' ? ' selected' : '')} to={`/de/${location.pathname.replace('/de/', '/')}`}>DE</Link>
                                </div>
                            ) : ''}
                            <button className="navbar-menu-toggle navbar-item" onClick={() => toggleMenuState()}>
                                <span className={"navbar-open menu-text" + (isMenuDisplayed ? '' : ' visible')}>
                                    Menu
                                    </span>
                                <div id="nav-icon" className={"" + (isMenuDisplayed ? 'open' : '')}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span className="last"></span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}


export default Header;