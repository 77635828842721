import React from 'react';
import { Link } from "gatsby"

import './menu.scss';

const ListLink = props => (
  <li className="Navigation-Element">
    <span className="slide-left-right">
      <Link to={props.to} onClick={props.toggleMenuState}>
        {props.children}&nbsp;
      </Link>
    </span>
  </li>
)

function Menu({ isMenuDisplayed, toggleMenuState, lang = 'default', location = null }) {

  let dictionaryList = {
    en: {
      whatwedo: 'Services',
      whoWeAre: 'About',
      work: 'Our work',
      careers: 'Careers',
      contact: 'Contact'
    },
    de: {
      whatwedo: 'Angebot',
      whoWeAre: 'Über uns',
      work: 'Portfolio',
      careers: 'Jobs',
      contact: 'Kontakt'
    },
  };

  let content = {};
  let langSlug = ''

  if (lang === "de") {
    content = dictionaryList.de;
    langSlug = 'de/';
  } else {
    content = dictionaryList.en;
  }


  return (
    <div className={"fadeMe " + (isMenuDisplayed ? 'show' : 'hidden')}>
      <section className="section content-section Menu">
        <div className="container" style={{ height: "100%" }}>
          <div className="outer">
            <div className="inner">
              <div className="column is-half is-offset-one-quarter">
                <ul>
                  <ListLink to={"/services"} toggleMenuState={toggleMenuState}>{content.whatwedo}</ListLink>
                  <ListLink to={langSlug + "/#who-we-are"} toggleMenuState={toggleMenuState}>{content.whoWeAre}</ListLink>
                  <ListLink to={"/work"} toggleMenuState={toggleMenuState}>{content.work}</ListLink>
                  <ListLink to={"/blog"} toggleMenuState={toggleMenuState}>Blog</ListLink>
                  <ListLink to={"/careers"} toggleMenuState={toggleMenuState}>{content.careers}</ListLink>
                  <ListLink to={langSlug + "/contact"} toggleMenuState={toggleMenuState}>{content.contact}</ListLink>
                  <li className="languageSelection__wrapper">
                    <Link className={"Language__item" + (lang === 'default' ? ' selected' : '')} to={location.pathname.replace('/de/', '/')} toggleMenuState={toggleMenuState}>English</Link>
                    <Link className={"Language__item" + (lang === 'de' ? ' selected' : '')} to={`/de/`} toggleMenuState={toggleMenuState}>Deutsch</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div >
  );

}

export default Menu;