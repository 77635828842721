import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import parse from 'html-react-parser';
import "./layout.scss"
import Menu from './menu';
import Header from '../components/header';
import { Helmet } from 'react-helmet';

const dictionaryList = {
  en: {
    emailCTA: 'Get in touch',
    country: 'Switzerland',
    privacyPolicy: 'Privacy Policy'
  },
  de: {
    emailCTA: 'Kontakt',
    country: '',
    privacyPolicy: 'Datenschutzerklärung'
  }
}

function Footer({ lang }) {

  let content;
  lang === "de" ? (content = dictionaryList.de) : (content = dictionaryList.en)

  const homeLink = `/${lang === 'de' ? 'de' : ''}`;

  const linkedinSvgString = '<svg class="footer__socialicon" height="18px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><rect y="160" width="114.496" height="352"/></g></g><g><g><path d="M426.368,164.128c-1.216-0.384-2.368-0.8-3.648-1.152c-1.536-0.352-3.072-0.64-4.64-0.896 c-6.08-1.216-12.736-2.08-20.544-2.08c-66.752,0-109.088,48.544-123.04,67.296V160H160v352h114.496V320 c0,0,86.528-120.512,123.04-32c0,79.008,0,224,0,224H512V274.464C512,221.28,475.552,176.96,426.368,164.128z"/></g></g><g><g><circle cx="56" cy="56" r="56"/></g></g></svg>';
  const instagramSvgString = '<svg class="footer__socialicon" version="1.1" height="18px" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160 C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48 h192c61.76,0,112,50.24,112,112V352z"/></g></g><g><g><path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336 c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"/></g></g><g><g><circle cx="393.6" cy="118.4" r="17.056"/></g></g></svg>';

  return (
    <footer className="section dark-section footer">
      <div className="container">
        <div className="columns">
          <div className="column is-4">
            <Link className="Footer__logoLink" to={homeLink}>
              <p className="logo">
                <img className="brand-mark" alt="Voa Labs Logo" src="/img/brand-mark.png" width="35px" /> VOA LABS
              </p>
            </Link>
          </div>
          <div className="column is-5">
            <p className="footer__message">
              Partnering with everyone, from fast-moving startups to experienced enterprises, to help build digital products that are simple, beautiful, and easy to use.<span role="img" aria-label="rocket">🚀</span>
            </p>

            <div className="columns">
              <div className="column is-6">
                <p className="footer__title">
                  Studio
            </p>
                <p>
                  Zürichstrasse 137<br /> 
                  8600 Dübendorf<br />
                  {parse(content.country)}
                </p>
              </div>
              <div className="column is-6">
                <p className="footer__title">
                  {parse(content.emailCTA)}
                </p>
                <p>
                  <a href="mailto:hello@voalabs.com">hello@voalabs.com</a>
                </p>
              </div>
            </div>

          </div>
          <div className="column is-3">
          </div>
        </div>
      </div>
      <div className="container ">

      </div>
      <div className="container copyright">
        <div className="footer__hr"></div>
        <div className="columns">
          <div className="column is-9">Copyright © Voa Labs 2021. All rights reserved. <br /><Link className="" to={`${lang === 'de' ? '/de' : ''}/privacy-policy`}>{content.privacyPolicy}</Link></div>
          <div className="column is-3 footer__socialcontainer">
            <ul>
              <li className="footer-socialelement">
                <a href="https://www.linkedin.com/company/voa-labs/" target="_blank" rel="noopener noreferrer">
                  <div dangerouslySetInnerHTML={{ __html: linkedinSvgString }} />
                </a>
              </li>
              <li className="footer-socialelement">
                <a href="https://www.instagram.com/voalabs/" target="_blank" rel="noopener noreferrer">
                  <div dangerouslySetInnerHTML={{ __html: instagramSvgString }} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

function Layout({ children, lang, location, showLanguageSelector }) {

  const [isMenuDisplayed, setMenuState] = useState(false);

  function toggleMenuState() {
    setMenuState(!isMenuDisplayed);
  }

  useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (isMenuDisplayed) {
      document.body.classList.add('menu-open');
      document.body.classList.remove('whiteHeader-show');
    } else {
      document.body.classList.remove('menu-open');
      if (bodyElement.classList.contains("whiteHeader")) {
        document.body.classList.add('whiteHeader-show');
      }

    }
  }, [isMenuDisplayed]); // Only re-run the effect if count changes

  return (
    <div className="App">
      <Helmet>
      </Helmet>
      <Menu toggleMenuState={toggleMenuState} isMenuDisplayed={isMenuDisplayed} lang={lang} location={location} />
      <Header toggleMenuState={toggleMenuState} isMenuDisplayed={isMenuDisplayed} lang={lang} location={location} showLanguageSelector={showLanguageSelector} />
      <div className="Site-content">
        {children}
      </div>
      <Footer lang={lang} />
    </div>
  )
}


export default Layout;